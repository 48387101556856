import React from "react"
import PropTypes from "prop-types"

const TagList = ({ tags }) => {
  const hashLinks = tags
    .map((tag, i) => (
      <a
        className="tag"
        style={{ fontWeight: 400 }}
        key={i}
        href={`/tags/${tag}/`}
      >
        <span>#{tag}</span>
      </a>
    ))
    .reduce((acc, curr, i) => [...acc, i !== 0 ? " " : "", curr], [])
  return (
    <span
      style={{
        display: "inline-block",
        margin: 0,
      }}
    >
      {hashLinks}
    </span>
  )
}

TagList.propTypes = {
  tags: PropTypes.arrayOf(PropTypes.string),
}

export default TagList
