import React from "react"
import { Link, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import { PageSEO } from "../components/seo"
import TagList from "../components/TagList"

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  const siteDescription = data.site.siteMetadata.description
  const posts = data.allMarkdownRemark.edges

  return (
    <Layout
      noTitle
      location={location}
      title={siteTitle}
      description={siteDescription}
    >
      <PageSEO title="Sagi Kedmi - שגיא קדמי" />
      <div style={{ paddingBottom: 20 }}>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <GatsbyImage
            alt="sagi kedmi"
            image={data.himselfDots.childImageSharp.gatsbyImageData}
            style={{
              minWidth: 150,
              display: "inline-block",
              clipPath:
                "polygon(45% 0, 73% 9%, 100% 46%, 62% 96%, 9% 100%, 8% 23%)",
            }}
          />
          <div style={{ paddingLeft: 20 }}>
            <p>Hey, I'm Sagi.</p>
            <p>
              I am a founder at{" "}
              <a href="https://opensay.co/?s=sagi.io">OpenSay</a>, advisory
              board at several startups and a family man.
            </p>
            <p>
              I also run <a href="https://indietlv.com/?s=sagi.io">Indie TLV</a>{" "}
              - a community of bootstrappers.
            </p>
            {/*
            <p> I failed more than most.</p>
            */}
            <p>
              I welcome emails at <a href="mailto:sagi@hey.com">sagi@hey.com</a>
              .
            </p>
          </div>
        </div>
        <GatsbyImage
          alt="broken canary"
          image={data.hero.childImageSharp.gatsbyImageData}
          style={{ paddingBottom: 20 }}
        />
      </div>

      {posts
        .filter(node => {
          if (
            process.env.NODE_ENV === "production" &&
            node.frontmatter?.draft === true
          ) {
            console.log("A")
            return false
          }
          return true
        })

        .map(({ node }) => {
          const title = node.frontmatter.title || node.fields.slug
          const isDraft = !!node.frontmatter.draft
          const { description, tags } = node.frontmatter
          const isDev = process.env.NODE_ENV === "development"

          if (isDraft && !isDev) {
            return <></>
          }
          return (
            <article key={node.fields.slug}>
              <header style={{ marginBottom: "1em" }}>
                <h4
                  style={{
                    marginTop: 0,
                    marginBottom: 0,
                  }}
                >
                  <span
                    style={{
                      backgroundImage:
                        "linear-gradient(0deg,#d9f5fb 10%,transparent 40%)",
                    }}
                  >
                    <Link
                      style={{
                        boxShadow: `none`,
                        color: "rgba(0,0,0,0.8)",
                        fontWeight: 500,
                      }}
                      to={node.fields.slug}
                    >
                      {isDraft ? " | DRAFT | " : ""}
                      {title}
                    </Link>
                  </span>
                </h4>
                <p style={{ color: "#585858", margin: 0 }}>{description}</p>
                <TagList tags={tags} />
              </header>
            </article>
          )
        })}
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  {
    hero: file(absolutePath: { regex: "/dead_canary.jpg/" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    himselfDots: file(absolutePath: { regex: "/himself_dots.jpg/" }) {
      childImageSharp {
        gatsbyImageData(width: 150, height: 150, layout: FIXED)
      }
    }
    himself: file(absolutePath: { regex: "/himself.jpg/" }) {
      childImageSharp {
        gatsbyImageData(width: 150, height: 150, layout: FIXED)
      }
    }
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMM DD, YYYY")
            title
            description
            tags
            draft
          }
        }
      }
    }
  }
`
